import React from 'react';
import '../styles/Intro.css';
import IntroPage from './me'

const Intro = () => {

  return (
    <section id='Intro'>
      <IntroPage /> 
    </section>
  )
}

export default Intro
 